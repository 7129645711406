export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_END = 'SIGNIN_END';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const CHECK_EMAIL_START = 'CHECK_EMAIL_START';
export const CHECK_EMAIL_END = 'CHECK_EMAIL_END';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_END = 'SIGNUP_END';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const GET_ZONE_LIST_START = 'GET_ZONE_LIST_START';
export const GET_ZONE_LIST_SUCCESS = 'GET_ZONE_LIST_SUCCESS';
export const GET_ZONE_LIST_FAIL = 'GET_ZONE_LIST_FAIL';
export const GET_ZONE_LIST_CLEAR = 'GET_ZONE_LIST_CLEAR';

export const GET_GCP_ZONES_START = 'GET_GCP_ZONES_START';
export const GET_GCP_ZONES_SUCCESS = 'GET_GCP_ZONES_SUCCESS';
export const GET_GCP_ZONES_FAIL = 'GET_GCP_ZONES_FAIL';
export const GET_GCP_ZONES_CLEAR = 'GET_GCP_ZONES_CLEAR';

export const CREATE_SCAN_START = 'CREATE_SCAN_START';
export const CREATE_SCAN_SUCCESS = 'CREATE_SCAN_SUCCESS';
export const CREATE_SCAN_FAIL = 'CREATE_SCAN_FAIL';
export const CREATE_SCAN_CLEAR = 'CREATE_SCAN_CLEAR';

export const GET_SCANS_START = 'GET_SCANS_START';
export const GET_SCANS_SUCCESS = 'GET_SCANS_SUCCESS';
export const GET_SCANS_FAIL = 'GET_SCANS_FAIL';
export const GET_SCANS_CLEAR = 'GET_SCANS_CLEAR';

export const DELETE_SCAN_START = 'DELETE_SCAN_START';
export const DELETE_SCAN_SUCCESS = 'DELETE_SCAN_SUCCESS';
export const DELETE_SCAN_FAIL = 'DELETE_SCAN_FAIL';
export const DELETE_SCAN_CLEAR = 'DELETE_SCAN_CLEAR';

export const ADD_TO_SELECTED_SCANS_QUEUE = 'ADD_TO_SELECTED_SCANS_QUEUE';
export const CLEAR_SELECTED_SCANS_QUEUE = 'CLEAR_SELECTED_SCANS_QUEUE';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';
export const DOWNLOAD_FILE_STATE_CLEAR = 'DOWNLOAD_FILE_STATE_CLEAR';

export const UPDATE_SCAN_START = 'UPDATE_SCAN_START';
export const UPDATE_SCAN_SUCCESS = 'UPDATE_SCAN_SUCCESS';
export const UPDATE_SCAN_FAIL = 'UPDATE_SCAN_FAIL';
export const UPDATE_SCAN_STATE_CLEAR = 'UPDATE_SCAN_STATE_CLEAR';

export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS"
export const SIGN_OUT_FAIL = "SIGN_OUT_FAIL"

export const SEND_EMAIL_VERIFICATION = "SEND_EMAIL_VERIFICATION"
export const SEND_EMAIL_VERIFICATION_FAIL = "SEND_EMAIL_VERIFICATION_FAIL"